import { computed, inject } from '@angular/core';

import { tapResponse } from '@ngrx/operators';
import {
  patchState,
  signalStore,
  type,
  withComputed,
  withMethods,
  withState
} from '@ngrx/signals';
import {
  entityConfig,
  removeAllEntities,
  removeEntities,
  removeEntity,
  setAllEntities,
  setEntities,
  updateEntity,
  withEntities
} from '@ngrx/signals/entities';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { mergeMap, pipe, switchMap, tap } from 'rxjs';

import { withDevtools } from '../../functions/redux-dev-tools';
import { StrictEntityMap } from '../../models/entity.models';
import { ErrorResponse, RxMethodCallback } from '../../models/rxMethod.models';
import {
  Campaign,
  CampaignDraft,
  GetCampaignDraftsRequest,
  GetCampaignDraftsResponse,
  GetCampaignOrgMetricsPayload,
  GetCampaignOrgMetricsResponse,
  GetCampaignUserMetricsPayload,
  GetCampaignUserMetricsResponse,
  GetSentCampaignMetricsRequest,
  GetSentCampaignMetricsResponse,
  GetSentCampaignsRequest,
  GetSentCampaignsResponse,
  NotificationEmail,
  SentCampaign,
  SentCampaignMetrics,
  UpdateNotificationEmailPayload,
  UpsertCampaignPayload
} from './email.models';
import { EmailService } from './email.service';

export interface EmailState {
  getCampaignDraftsInflight: boolean;
  getSentCampaignsInflight: boolean;
  getSentCampaignMetricsInflight: boolean;
  sentCampaignMetrics: SentCampaignMetrics | null;
  upsertCampaignInflight: boolean;
  sendCampaignInflight: boolean;
  sendTestCampaignInflight: boolean;
  getCampaignUserMetricsInflight: boolean;
  getCampaignOrgMetricsInflight: boolean;
  getCampaignDetailsInflight: boolean;
  deleteCampaignsInflight: boolean;
  getEventNotificationEmailsInflight: boolean;
  updateEventNotificationEmailsInflight: boolean;
}

export const defaultEmailState: EmailState = {
  getCampaignDraftsInflight: false,
  getSentCampaignsInflight: false,
  getSentCampaignMetricsInflight: false,
  sentCampaignMetrics: null,
  sendTestCampaignInflight: false,
  upsertCampaignInflight: false,
  sendCampaignInflight: false,
  getCampaignUserMetricsInflight: false,
  getCampaignOrgMetricsInflight: false,
  getCampaignDetailsInflight: false,
  deleteCampaignsInflight: false,
  getEventNotificationEmailsInflight: false,
  updateEventNotificationEmailsInflight: false
};

export const campaignDraftsCollection = entityConfig({
  entity: type<CampaignDraft>(),
  selectId: (draft) => draft.id,
  collection: 'campaignDrafts'
});

export const sentCampaignsCollection = entityConfig({
  entity: type<SentCampaign>(),
  selectId: (campaign) => campaign.campaignId,
  collection: 'sentCampaigns'
});

export const notificationEmailsCollection = entityConfig({
  entity: type<NotificationEmail>(),
  selectId: (email) => email.id,
  collection: 'notificationEmails'
});

export const EmailStore = signalStore(
  { providedIn: 'root' },
  withDevtools('email'),
  withState<EmailState>(defaultEmailState),
  withEntities(campaignDraftsCollection),
  withEntities(sentCampaignsCollection),
  withEntities(notificationEmailsCollection),
  withComputed((store) => ({
    strictCampaignDraftsEntityMap: computed(
      () => store.campaignDraftsEntityMap() as StrictEntityMap<CampaignDraft>
    ),
    strictSentCampaignsEntityMap: computed(
      () => store.sentCampaignsEntityMap() as StrictEntityMap<SentCampaign>
    ),
    strictNotificationEmailsEntityMap: computed(
      () =>
        store.notificationEmailsEntityMap() as StrictEntityMap<NotificationEmail>
    ),
    guestNotificationEmails: computed(() =>
      [
        ...store
          .notificationEmailsEntities()
          .filter(({ entity }) => entity === 'GUEST')
      ].sort((a, b) => a.type.localeCompare(b.type))
    ),
    hostNotificationEmails: computed(() =>
      [
        ...store
          .notificationEmailsEntities()
          .filter(({ entity }) => entity === 'HOST')
      ].sort((a, b) => a.type.localeCompare(b.type))
    ),
    coordinatorNotificationEmails: computed(() =>
      [
        ...store
          .notificationEmailsEntities()
          .filter(({ entity }) => entity === 'COORDINATOR')
      ].sort((a, b) => a.type.localeCompare(b.type))
    )
  })),
  withMethods((store) => {
    const emailService = inject(EmailService);
    return {
      getCampaignDetails: rxMethod<{ id: string } & RxMethodCallback<Campaign>>(
        pipe(
          tap(() =>
            patchState<EmailState>(store, { getCampaignDetailsInflight: true })
          ),
          mergeMap(({ id, onSuccess, onFail }) =>
            emailService.getCampaignDetails$(id).pipe(
              tapResponse({
                next: (campaign) => {
                  patchState<EmailState>(store, {
                    getCampaignDetailsInflight: false
                  });

                  onSuccess?.(campaign);
                },
                error: () => {
                  patchState<EmailState>(store, {
                    getCampaignDetailsInflight: false
                  });

                  onFail?.();
                }
              })
            )
          )
        )
      ),
      getCampaignDrafts: rxMethod<
        GetCampaignDraftsRequest & RxMethodCallback<GetCampaignDraftsResponse>
      >(
        pipe(
          tap(() => patchState(store, { getCampaignDraftsInflight: true })),
          mergeMap(({ size, page, payload, onSuccess, onFail }) =>
            emailService.getCampaignDrafts$(size, page, payload).pipe(
              tapResponse({
                next: (response) => {
                  patchState(
                    store,
                    setEntities(response.content, campaignDraftsCollection)
                  );
                  patchState(store, {
                    getCampaignDraftsInflight: false
                  });

                  onSuccess?.(response);
                },
                error: () => {
                  patchState(store, {
                    getCampaignDraftsInflight: false
                  });

                  onFail?.();
                }
              })
            )
          )
        )
      ),
      getSentCampaigns: rxMethod<
        GetSentCampaignsRequest & RxMethodCallback<GetSentCampaignsResponse>
      >(
        pipe(
          tap(() =>
            patchState<EmailState>(store, { getSentCampaignsInflight: true })
          ),
          mergeMap(({ size, page, payload, onSuccess, onFail }) =>
            emailService.getSentCampaigns$(size, page, payload).pipe(
              tapResponse({
                next: (response) => {
                  patchState(
                    store,
                    setEntities(
                      response.emailCampaigns.content,
                      sentCampaignsCollection
                    )
                  );
                  patchState(store, {
                    sentCampaignMetrics: response.campaignMetrics,
                    getSentCampaignsInflight: false
                  });

                  onSuccess?.(response);
                },
                error: () => {
                  patchState<EmailState>(store, {
                    getSentCampaignsInflight: false
                  });

                  onFail?.();
                }
              })
            )
          )
        )
      ),
      getSentCampaignMetrics: rxMethod<
        GetSentCampaignMetricsRequest &
          RxMethodCallback<GetSentCampaignMetricsResponse>
      >(
        pipe(
          tap(() =>
            patchState<EmailState>(store, {
              getSentCampaignMetricsInflight: true
            })
          ),
          mergeMap(({ payload, onSuccess, onFail }) =>
            emailService.getSentCampaignMetrics$(payload).pipe(
              tapResponse({
                next: (response) => {
                  patchState<EmailState>(store, {
                    getSentCampaignMetricsInflight: false
                  });

                  onSuccess?.(response);
                },
                error: () => {
                  patchState<EmailState>(store, {
                    getSentCampaignMetricsInflight: false
                  });

                  onFail?.();
                }
              })
            )
          )
        )
      ),
      upsertCampaign: rxMethod<
        UpsertCampaignPayload & RxMethodCallback<Campaign>
      >(
        pipe(
          tap(() =>
            patchState<EmailState>(store, {
              upsertCampaignInflight: true
            })
          ),
          mergeMap(({ payload, onSuccess, onFail }) =>
            emailService.upsertCampaign$(payload).pipe(
              tapResponse({
                next: (response) => {
                  patchState<EmailState>(store, {
                    upsertCampaignInflight: false
                  });

                  onSuccess?.(response);
                },
                error: () => {
                  patchState<EmailState>(store, {
                    upsertCampaignInflight: false
                  });

                  onFail?.();
                }
              })
            )
          )
        )
      ),
      sendCampaign: rxMethod<
        UpsertCampaignPayload & RxMethodCallback<Campaign>
      >(
        pipe(
          tap(() =>
            patchState(store, {
              sendCampaignInflight: true
            })
          ),
          mergeMap(({ payload, onSuccess, onFail }) =>
            emailService.sendCampaign$(payload).pipe(
              tapResponse({
                next: (campaign) => {
                  patchState(store, {
                    sendCampaignInflight: false
                  });

                  patchState(
                    store,
                    removeEntity(campaign.id, campaignDraftsCollection)
                  );

                  onSuccess?.(campaign);
                },
                error: (error?: ErrorResponse) => {
                  patchState(store, {
                    sendCampaignInflight: false
                  });

                  onFail?.(error);
                }
              })
            )
          )
        )
      ),
      sendTestCampaign: rxMethod<
        UpsertCampaignPayload & RxMethodCallback<Campaign>
      >(
        pipe(
          tap(() =>
            patchState(store, {
              sendTestCampaignInflight: true
            })
          ),
          switchMap(({ payload, onSuccess, onFail }) =>
            emailService.sendTestCampaign$(payload).pipe(
              tapResponse({
                next: (campaign) => {
                  patchState(store, {
                    sendTestCampaignInflight: false
                  });

                  onSuccess?.(campaign);
                },
                error: (error?: ErrorResponse) => {
                  patchState(store, {
                    sendTestCampaignInflight: false
                  });

                  onFail?.(error);
                }
              })
            )
          )
        )
      ),
      getCampaignUserMetrics: rxMethod<
        GetCampaignUserMetricsPayload &
          RxMethodCallback<GetCampaignUserMetricsResponse>
      >(
        pipe(
          tap(() =>
            patchState<EmailState>(store, {
              getCampaignUserMetricsInflight: true
            })
          ),
          switchMap(({ payload, onSuccess, onFail }) =>
            emailService.campaignUserMetrics$(payload).pipe(
              tapResponse({
                next: (response) => {
                  patchState<EmailState>(store, {
                    getCampaignUserMetricsInflight: false
                  });

                  onSuccess?.(response);
                },
                error: () => {
                  patchState<EmailState>(store, {
                    getCampaignUserMetricsInflight: false
                  });

                  onFail?.();
                }
              })
            )
          )
        )
      ),
      getCampaignOrgMetrics: rxMethod<
        GetCampaignOrgMetricsPayload &
          RxMethodCallback<GetCampaignOrgMetricsResponse>
      >(
        pipe(
          tap(() =>
            patchState<EmailState>(store, {
              getCampaignOrgMetricsInflight: true
            })
          ),
          switchMap(({ payload, onSuccess, onFail }) =>
            emailService.campaignOrgMetrics$(payload).pipe(
              tapResponse({
                next: (response) => {
                  patchState<EmailState>(store, {
                    getCampaignOrgMetricsInflight: false
                  });

                  onSuccess?.(response);
                },
                error: () => {
                  patchState<EmailState>(store, {
                    getCampaignOrgMetricsInflight: false
                  });

                  onFail?.();
                }
              })
            )
          )
        )
      ),
      deleteCampaigns: rxMethod<{ ids: string[] } & RxMethodCallback<void>>(
        pipe(
          tap(() =>
            patchState(store, {
              deleteCampaignsInflight: true
            })
          ),
          switchMap(({ ids, onSuccess, onFail }) =>
            emailService.deleteCampaigns$(ids).pipe(
              tapResponse({
                next: () => {
                  patchState(
                    store,
                    removeEntities(ids, campaignDraftsCollection)
                  );
                  patchState(store, {
                    deleteCampaignsInflight: false
                  });

                  onSuccess?.();
                },
                error: () => {
                  patchState(store, {
                    deleteCampaignsInflight: false
                  });

                  onFail?.();
                }
              })
            )
          )
        )
      ),
      getEventNotificationEmails: rxMethod<
        { eventId: string } & RxMethodCallback<NotificationEmail[]>
      >(
        pipe(
          tap(() =>
            patchState(store, {
              getEventNotificationEmailsInflight: true
            })
          ),
          switchMap(({ eventId, onSuccess, onFail }) =>
            emailService.getEventNotificationEmails$(eventId).pipe(
              tapResponse({
                next: (emails) => {
                  patchState(
                    store,
                    setAllEntities(emails, notificationEmailsCollection)
                  );
                  patchState(store, {
                    getEventNotificationEmailsInflight: false
                  });

                  onSuccess?.(emails);
                },
                error: () => {
                  patchState(store, {
                    getEventNotificationEmailsInflight: false
                  });

                  onFail?.();
                }
              })
            )
          )
        )
      ),
      updateEventNotificationEmail: rxMethod<
        UpdateNotificationEmailPayload & RxMethodCallback<NotificationEmail>
      >(
        pipe(
          tap(() =>
            patchState(store, {
              updateEventNotificationEmailsInflight: true
            })
          ),
          switchMap(({ eventId, payload, onSuccess, onFail }) =>
            emailService.updateEventNotificationEmail$(eventId, payload).pipe(
              tapResponse({
                next: (email) => {
                  patchState(
                    store,
                    updateEntity(
                      { id: payload.id, changes: email },
                      notificationEmailsCollection
                    )
                  );
                  patchState(store, {
                    updateEventNotificationEmailsInflight: false
                  });

                  onSuccess?.(email);
                },
                error: () => {
                  patchState(store, {
                    updateEventNotificationEmailsInflight: false
                  });

                  onFail?.();
                }
              })
            )
          )
        )
      ),
      reset: () => {
        patchState(store, removeAllEntities(campaignDraftsCollection));
        patchState(store, removeAllEntities(sentCampaignsCollection));
        patchState(store, { sentCampaignMetrics: null });
      }
    };
  })
);
